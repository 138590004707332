<template>
	<section class="section_image_bg"  :style="{ background: background_color }">
		<div class="image_bg_content" ref="target" :id="'ita' + randNum">

			<div class="container"> 
				<div class="flex">
					<div class="open_lightbox" @click="openLightbox" :class="'article_lightbox_open' + randNum">
						<img :src="require(`@/assets/img/${image}`)" class="ita_image" alt="" :id="'ita_image' + randNum" :class="'ita_image' + randNum">
						
						<div class="lightbox_description">
							<span>Click on the image to enlarge</span>
							<svg version="1.1" id="Lager_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 816.5 814.3" style="enable-background:new 0 0 816.5 814.3;" xml:space="preserve"><g><path style="fill:#222;" d="M696.8,359.5c0.2,20-4.1,50.8-12.3,80.9c-9.8,35.6-24.9,69-45.6,99.7c-5.7,8.4-11.6,16.6-17.9,24.6c-2.7,3.5-2.6,5.5,0.5,8.7c60.8,60.6,121.6,121.2,182.3,181.9c13.1,13.1,16.2,28.3,8.7,42.5c-6.2,11.9-18.2,17.8-32.6,16.3c-9.9-1-18.2-4.8-25.5-12c-59.9-60.1-120-120-180-180.1c-3.8-3.8-6.1-3.8-10.2-0.6c-29.3,23.4-61.8,41.3-97,54.2c-28.1,10.3-57.1,16.8-86.8,19.4c-31.9,2.8-63.8,1.4-95.5-4.4c-36.5-6.7-70.8-19.1-103.2-36.8c-36.5-19.9-68.6-45.6-95.9-77.1c-34-39.3-58.5-83.7-72.7-133.7C1.7,402.5-2.2,361.4,1.2,319.4c4.7-56.8,22.4-109,52.8-157.1c24-38,54.2-70.1,90.7-96.4c34-24.5,71.2-42.4,111.6-53.6C298,0.9,340.2-2.4,383,1.7C427.8,6,470.2,18.8,510,39.9C534.6,53,557.5,68.6,578.5,87c21.9,19.2,41.1,40.8,57.5,64.7c17.3,25.3,31.3,52.5,41.5,81.5C690.5,270.4,697,308.8,696.8,359.5z M348.4,628c155.6-0.8,279.9-125.2,280.1-279.8c0.2-154.5-125.4-279.8-280.1-279.6C193.6,68.8,68.4,193,68.4,348.2C68.4,503,192.6,627.1,348.4,628z"/><path style="fill:#222;" d="M382.5,314.1c2.8,0,4.8,0,6.8,0c24.1,0,48.3-0.1,72.4,0c14.4,0.1,25.9,5.6,32.9,18.6c11.1,20.6-2.8,47.1-26.1,48.9c-14.7,1.1-29.6,0.5-44.4,0.5c-11.8,0.1-23.6,0.2-35.5-0.1c-4.7-0.1-6.3,1.2-6.2,6.1c0.2,24.1,0.1,48.3,0.1,72.4c0,12-3.2,22.7-13.3,30.3c-10.6,8-22.2,9.7-34.3,4.3c-11.7-5.1-19.1-14.3-19.8-27.2c-0.9-15.1-0.5-30.3-0.5-45.4c-0.1-13.1,0-26.3,0-39.9c-2.4-0.2-4.5-0.5-6.5-0.5c-24.5,0-49,0.1-73.4-0.1c-18.2-0.1-30.4-9.2-35.1-25.9c-4.8-16.7,6.6-36.5,23.6-40.4c4.7-1.1,9.5-1.7,14.3-1.7c24-0.2,48-0.2,71.9,0c4.1,0,5.4-1.1,5.4-5.3c-0.2-25-0.2-49.9-0.1-74.9c0.1-17,8.5-28.7,24-34.1c18.2-6.3,39.7,6.5,42.9,25.5c0.7,3.9,1,7.9,1,11.9c0.1,23.3,0,46.6,0,69.9C382.5,309.2,382.5,311.2,382.5,314.1z"/></g></svg>
						</div>

					</div>
				</div> <!-- flex -->

				<!-- lightbox -->
				<div ref="lightbox_overlay_id" class="lightbox_overlay" :class="'lightbox_overlay' + randNum" @click="closeLightbox">

					<div class="lightbox_container" :class="'lightbox_container' + randNum" ref="lightbox_id">

						<!-- close -->
						<div @click="closeLightbox" class="lightbox_close" ref="lightbox_close">
							<span>Close </span>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" style="width: 0.875em; height: 0.875em;"><path id="Path_1499" data-name="Path 1499" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#000"></path></svg>
						</div>

						<div class="lightbox_inner">

							<!-- image -->
							<figure class="lightbox_image">
								<picture>
									<img :src="require(`@/assets/img/${image}`)" alt="LIGHTBOX">
								</picture>
							</figure>

						</div> <!-- inner -->
					</div> <!-- lightbox_container -->

					<div class="click_area"></div>
				</div>
				<!-- end lightbox -->	

			</div> <!-- ita container -->
		</div>
	</section>
</template>

<script>
// ImageTextAccordion = ita
import { computed, ref, onMounted, onUnmounted } from 'vue'
import gsap from 'gsap'

export default {
	name:'Lightbox',
	props: {
		background_color: String,
		image: String
	},
	components: {
	},
	setup(props){
		const randNum = '_' + Math.floor(Math.random() * 1000000)
		const background_color = props.background_color		
		const image = props.image		
		const target = ref();

		// LIGHTBOX
		const lightbox_id = ref(null)
		const lightbox_overlay_id = ref(null)
		const lightbox_close = ref(null)

		const openLightbox = () => {

			lightbox_id.value.style.opacity = "0";
			lightbox_id.value.style.display = "block";

			lightbox_overlay_id.value.style.display = "block";
			lightbox_overlay_id.value.style.opacity = "0";

			gsap.to(lightbox_overlay_id.value,{
				duration: 0.5,
				delay:0.0,
				opacity:1
			})

			gsap.to(lightbox_id.value,{
				duration: 0.5,
				delay:0.2,
				ease: 'sine.out',
				top: '0%',
				opacity:1
			})
		}

		const closeLightbox = (e) => {
			// check if coorect element is clicked 
			if( e.target.classList.contains("click_area") ){

				gsap.to(lightbox_overlay_id.value,{
					duration: 0.5,
					delay:0.2,
					opacity:0,
					onComplete: function(){
						gsap.set(lightbox_overlay_id.value, {display: 'none'})
					}
				})

				gsap.to(lightbox_id.value,{
					duration: 0.5,
					delay:0.0,
					top: '15%',
					ease: 'sine.in',
					opacity:0,
					onComplete: function(){
						gsap.set(lightbox_id.value, {display: 'none'})
					}
				})
			}
		}
		// END LIGHTBOX

		// INTERCEPT
		let onlyOnce = true;
		const observer = new IntersectionObserver(
			([entry]) => {
				if(entry.isIntersecting == true){
					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{ threshold: 0.5 } // 0.7
			);

		// INTERCEPT ANIMATION
		const inView = () => {

			// image
			gsap.to('.ita_image' + randNum, {
				duration: 1.0,
				y: 0,
				x: '0px',
				opacity: 1,
				ease: 'power2.out',
				delay: 0.2
			})

			// lightbox_description
			gsap.to('.lightbox_description', {
				duration: 1.0,
				y: 0,
				x: '0px',
				opacity: 1,
				ease: 'power2.out',
				delay: 0.4
			})

		}
		// END INTERCEPT

		onMounted(()=>{
			const body = document.getElementsByTagName("BODY")[0];		
			const comp = document.getElementById('ita' + randNum);
			const offset = comp.getBoundingClientRect();
			const top = offset.top;
			const height = comp.offsetHeight;

			// onMounted INTERCEPT
			observer.observe(target.value);

			// image
			gsap.set(['.ita_image' + randNum, '.lightbox_description'], {x: '-50px', opacity: 0 })

		})

		return {
			image,
			background_color,
			target,
			randNum,

			openLightbox,
			closeLightbox,
			lightbox_id,
			lightbox_overlay_id,
			lightbox_close
		}
	}
}
</script>

<style scoped>

</style>