<template>
	<footer>
		<div class="full_width black_background footer">

			<div class="container">
				
				<div class="flex">
		
					<div class="flex-inner">		
						<a class="footer_logo" href="https://www.ri.se/" target="_blank"><div class="footer_logo_image_white"></div></a>
					</div>

					<div class="flex-inner">		
						<p class="footer_text"><b>Address: </b>Hörneborgsvägen 10, 892 50 Domsjö</p>
						<p class="footer_text"><b>Phone: </b>+46 10-516 67 50</p>
						<p class="footer_text"><b>Web: </b><a href="https://www.ri.se/en/processum" target="_blank">www.ri.se/en/processum 
						<span class="link_arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" style="width: 0.75em; height: 0.75em;"><path id="Path_1458" data-name="Path 1458" d="M14.556,14.556H4.444V4.444H9.5V3H4.444A1.444,1.444,0,0,0,3,4.444V14.556A1.444,1.444,0,0,0,4.444,16H14.556A1.449,1.449,0,0,0,16,14.556V9.5H14.556ZM10.944,3V4.444h2.593l-7.1,7.1,1.018,1.018,7.1-7.1V8.056H16V3Z" transform="translate(-3 -3)" fill="currentColor"></path></svg></span>
						</a></p>						
					</div>

					<div class="flex-inner">
						<p class="footer_text footer_text_flex_end"><b>Links to previous project reports:</b></p>

						<p class="footer_text"><a href="https://www.ibaccelprojectreport.com/2021/" target="_blank">2021 - IB:ACCEL Project report 
						<span class="link_arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" style="width: 0.75em; height: 0.75em;"><path id="Path_1458" data-name="Path 1458" d="M14.556,14.556H4.444V4.444H9.5V3H4.444A1.444,1.444,0,0,0,3,4.444V14.556A1.444,1.444,0,0,0,4.444,16H14.556A1.449,1.449,0,0,0,16,14.556V9.5H14.556ZM10.944,3V4.444h2.593l-7.1,7.1,1.018,1.018,7.1-7.1V8.056H16V3Z" transform="translate(-3 -3)" fill="currentColor"></path></svg></span>
						</a></p>

						<p class="footer_text"><a href="https://issuu.com/riseprocessum/docs/ibaccell-rapport-221x327mm_eng_v2_uppslag" target="_blank">2020 - IB:ACCEL Project report 
						<span class="link_arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" style="width: 0.75em; height: 0.75em;"><path id="Path_1458" data-name="Path 1458" d="M14.556,14.556H4.444V4.444H9.5V3H4.444A1.444,1.444,0,0,0,3,4.444V14.556A1.444,1.444,0,0,0,4.444,16H14.556A1.449,1.449,0,0,0,16,14.556V9.5H14.556ZM10.944,3V4.444h2.593l-7.1,7.1,1.018,1.018,7.1-7.1V8.056H16V3Z" transform="translate(-3 -3)" fill="currentColor"></path></svg></span>
						</a></p>

						<p class="footer_text"><a href="https://issuu.com/riseprocessum/docs/ibaccell-projektrapport_2019" target="_blank">2019 - IB:ACCEL Project report 
						<span class="link_arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" style="width: 0.75em; height: 0.75em;"><path id="Path_1458" data-name="Path 1458" d="M14.556,14.556H4.444V4.444H9.5V3H4.444A1.444,1.444,0,0,0,3,4.444V14.556A1.444,1.444,0,0,0,4.444,16H14.556A1.449,1.449,0,0,0,16,14.556V9.5H14.556ZM10.944,3V4.444h2.593l-7.1,7.1,1.018,1.018,7.1-7.1V8.056H16V3Z" transform="translate(-3 -3)" fill="currentColor"></path></svg></span>
						</a></p>
					</div>
				</div>

				<p class="footer_text footer_copy_text">{{ $t('footer.copyright') }}</p>
				
			</div>
		</div>
	</footer>
</template>

<script>
export default {

}
</script>

<style>

</style>