<template>
	<section class="section_video_hero" ref="target">
		<video autoplay playsinline muted loop @click="clickOverlay" id="video_hero_overlay" src="@/assets/video/hero.mp4" type="video/mp4"
			:class="{ displayNone: hideElement }"
		>
		</video>
		<div class="container">
			<div class="video_player">
				<div id="video_hero_overlay_text">
					<div class="video_overlay_text_slogan">
						<span :class="'preheader' + randNum" class="preheader">{{ $t('video_hero_pre_header') }}</span><br/>						
						<h1 class="animated_header">
							<span :class="'header_row1' + randNum" class="header_row1"> {{ $t('video_hero_header_row1') }}</span>
							<span v-html="$t('video_hero_header_row2')" :class="'header_row2' + randNum" class="header_row2"></span>
						</h1>

					</div>
				</div>

			</div>
		</div>
	</section>
</template>

<script>
import { ref, onMounted } from 'vue'
import gsap from 'gsap'

import {SplitText} from '@/assets/js/SplitText.min.js';

export default {
	name: "VideoHero",
	setup(){
		
		let hideElement = ref(0)
		const randNum = '_' + Math.floor(Math.random() * 1000000)

		const clickOverlay = () => {
		
			let video = document.getElementById('video_hero');

			let vurl = require('@/assets/video/hero.mp4')
			let source = document.createElement('source');

			source.setAttribute('src', vurl );

			video.appendChild(source);
			video.play();

			const video_hero = document.getElementById('video_hero');
			const video_overlay_text = document.getElementById('video_overlay_text');

			hideElement.value = true;

			video_hero.play();
		}

			// INTERCEPT
			let onlyOnce = true;
			const target = ref();
			const observer = new IntersectionObserver(
				([entry]) => {
					if(entry.isIntersecting == true){

						if(onlyOnce == true){
							onlyOnce = false;
							inView();
						}
					}
				},
				{ threshold: 0.7 }
			);

		// INTERCEPT ANIMATION
		const inView = () => {

			// SPLIT TEXT
			// type:"words, lines, chars"
			// postion: "relative" = default || postion: "absolute" || obs absolute pajjar text flow vid minskning i size
			
			const preheader = new SplitText('.preheader' + randNum, {type:"words"})
			const header_row1 = new SplitText('.header_row1' + randNum, {type:"words"})
			const header_row2 = new SplitText('.header_row2' + randNum, {type:"words"})
			gsap.set(['.preheader' + randNum,'.header_row1' + randNum,'.header_row2' + randNum, '.video_overlay_text_name' + randNum], {opacity: 1})
		
			gsap.set(preheader.words, {opacity: 0, x: '-50', y: '0', scale: 10})
			gsap.set([header_row1.words, header_row2.words], {opacity: 0, y: '30', x: '0', scale: 1})

			// preheader loop
			for(let i = 0; i < preheader.words.length; i++){
				gsap.to(preheader.words[i], {
					duration: 0.5,
					opacity: 1,
					x: '0',
					y: 0,
					scale: 1,
					ease: 'power0.out',
					delay: 0.3 + (i * 0.05	)
				})
			}

			// header_row1 loop
			for(let i = 0; i < header_row1.words.length; i++){
				gsap.to(header_row1.words[i], {
					duration: 0.7,
					opacity: 1,
					x: '0',
					y: '0',
					scale: 1,
					ease: 'power2.out',
					delay: 1.0 + (i * 0.03	)
				})
			}

			// header_row2 loop
			for(let i = 0; i < header_row2.words.length; i++){
				gsap.to(header_row2.words[i], {
					duration: 0.7,
					opacity: 1,
					x: '0',
					y: '0',
					scale: 1,
					ease: 'power2.out',
					delay: 1.2 + (i * 0.03	)
				})
			}
			
		}
		// END INTERCEPT

		// onMounted INTERCEPT
		onMounted(()=>{
			observer.observe(target.value);
			gsap.set(['.preheader' + randNum,'.header_second' + randNum, '.video_overlay_text_name' + randNum, '.play_container', '.video_paragraph'], {opacity: 0})
			gsap.set(['.header_row1' + randNum,'.header_row2' + randNum], {opacity: 0})
		})
		return {
			target,
			clickOverlay,
			hideElement,
			randNum
		}
	}
}
</script>

<style>
	#trace{
		position: fixed;
		width:100%;
		height:40px;
		background:#fff;
		border:1 px solid #ccc;
		bottom:0;
		left:0;
		z-index: 999999;
	}

</style>