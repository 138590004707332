
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import i18n from '@/i18n'
import OnePage from '@/views/OnePage.vue'

const routes = [
	{
		path: '/se',
		name: 'Svenska',
		component: OnePage,
		beforeEnter: (to, from, next) => {
			document.title = "IB:Accel | Project report 2022";
			i18n.locale = to.params.locale;
			return next()
		}
	},
	{
		path: '/',
		name: 'English',
		component: OnePage,
		beforeEnter: (to, from, next) => {
			document.title = "IB:Accel | Project report 2022";
			i18n.locale = to.params.locale;
			return next()
		}
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: "/",
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router