<template>
	<section class="section_rippleeffect" ref="target"  :id="'rippleeffect' + randNum">
		<div class="rippleeffect_container">	
	
			<div class="numbers_container">
				<div class="num_col num_col1">
					<div class="indicator"><h3 class="header">{{ $t('rippleEffect.step1_header') }}</h3></div>
					<p class="num_text"><span class="num">{{ $t('rippleEffect.step1_num') }}</span>
					<span class="sek">{{ $t('rippleEffect.sek') }}</span></p>
					<div v-html="$t('rippleEffect.step1_text')"></div>
				</div>

				<div class="num_col num_col2">
					<div class="indicator"><h3 class="header">{{ $t('rippleEffect.step2_header') }}</h3></div>
					<p class="num_text"><span class="num">{{ $t('rippleEffect.step2_num') }}</span>
					<span class="sek">{{ $t('rippleEffect.sek') }}</span></p>
					<div v-html="$t('rippleEffect.step2_text')"></div>
				</div>

				<div class="num_col num_col3">
					<div class="indicator"><h3 class="header">{{ $t('rippleEffect.step3_header') }}</h3></div>
					<p class="num_text"><span class="num">{{ $t('rippleEffect.step3_num') }}</span>
					<span class="sek">{{ $t('rippleEffect.sek') }}</span></p>
					<div v-html="$t('rippleEffect.step3_text')"></div>
				</div>
			</div>

			<div class="accordion_content" :class="'accordion_content' + randNum">
				<h3 class="pre_header">{{ $t('rippleEffect.acc_pre_header') }}</h3>

				<!-- 1 -->
				<h4>{{ $t('rippleEffect.col1_header') }}</h4>
				<div class="flex">
					<div class="flex_half">
						<div v-html="$t('rippleEffect.col1_text1')"></div>
					</div>
					<div class="flex_half">
						<div v-html="$t('rippleEffect.col1_text2')"></div>
					</div>
				</div>

				<!-- 2 -->
				<h4>{{ $t('rippleEffect.col2_header') }}</h4>
				<div class="flex">
					<div class="flex_half">
						<div v-html="$t('rippleEffect.col2_text1')"></div>
					</div>
					<div class="flex_half">
						<div v-html="$t('rippleEffect.col2_text2')"></div>
					</div>
				</div>

				<!-- 3 -->
				<h4>{{ $t('rippleEffect.col3_header') }}</h4>
				<div class="flex">
					<div class="flex_half">
						<div v-html="$t('rippleEffect.col3_text1')"></div>
					</div>
					<div class="flex_half">
						<div v-html="$t('rippleEffect.col3_text2')"></div>
					</div>
				</div>

				<!-- 4 -->
				<h4>{{ $t('rippleEffect.col4_header') }}</h4>
				<div class="flex">
					<div class="flex_half">
						<div v-html="$t('rippleEffect.col4_text1')"></div>
					</div>
					<div class="flex_half">
						<div v-html="$t('rippleEffect.col4_text2')"></div>
					</div>
				</div>

			</div>

			<!-- button -->
			<a aria-label="Expand text to read more" class="ripple_button accordion_button" :class="{isOpen: isBtnOpen}" @click="changeAccordion">
				<span class="btn_open"><span class="btn_text">{{ $t('rippleEffect.btn_expander_open') }}</span> <span class="btn_icon open">+</span></span>
				<span class="btn_close"><span class="btn_text">{{ $t('rippleEffect.btn_expander_close') }}</span> <span class="btn_icon close">-</span></span>
			</a>

		</div>
	</section>
</template>

<script>
import { computed, ref, onMounted, onUnmounted } from 'vue'
import gsap from 'gsap'
import {SplitText} from '@/assets/js/SplitText.min.js';
import { accordionParallax } from '@/composables/parallax';

export default {
	name:'RippleEffect',
	props: {
		
	},
	components: {

	},
	setup(){
		const randNum = '_' + Math.floor(Math.random() * 1000000);
		const target = ref();
		let acc_open = false;
		let isBtnOpen = ref(0)
		
		// Accordion
		const changeAccordion = () => {
			isBtnOpen.value = !isBtnOpen.value;

			if(!acc_open){
				acc_open = true
				gsap.set('.accordion_content' + randNum, {height:"auto"})
				gsap.from('.accordion_content' + randNum, {duration: 0.7, height:0,
					onComplete: function(){
						accordionParallax()
					}
				})
			}else{
				acc_open = false
				gsap.to('.accordion_content' + randNum, {duration: 0.5, height:0,
					onComplete: function(){
						accordionParallax()
					}
				})
			}


		}
		// INTERCEPT
		let onlyOnce = true;
		const observer = new IntersectionObserver(
			([entry]) => {
				if(entry.isIntersecting == true){
					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{ threshold: 0.5 } // 0.7
			);

		// INTERCEPT ANIMATION
		const inView = () => {
			
			gsap.to('.num_col1', {
				duration: 1.0,
				y: 0,
				left: '0px',
				top: '0px',
				opacity: 1,
				ease: 'power2.out',
				delay: 0.2
			})

			gsap.to('.num_col2', {
				duration: 1.0,
				y: 0,
				left: '0px',
				top: '0px',
				opacity: 1,
				ease: 'power2.out',
				delay: 0.4
			})

			gsap.to('.num_col3', {
				duration: 1.0,
				y: 0,
				left: '0px',
				top: '0px',
				opacity: 1,
				ease: 'power2.out',
				delay: 0.6
			})

			// btn
			gsap.to('.ripple_button', {
				duration: 1.0,
				y: 0,
				left: '0px',
				top: '0px',
				opacity: 1,
				ease: 'power2.out',
				delay: 0.6
			})

		}
		// END INTERCEPT

		onMounted(()=>{
			const body = document.getElementsByTagName("BODY")[0];		
			const comp = document.getElementById('rippleeffect' + randNum);
			const offset = comp.getBoundingClientRect();
			const top = offset.top;
			const height = comp.offsetHeight;

			// SCROLL PARALLAX
/* 			const handleScroll = (evt) => {
				const window_height = window.innerHeight
				const image = document.getElementById('parallax_image' + randNum);
				const comp = document.getElementById('ita' + randNum);
				const comp_box = comp.getBoundingClientRect();
				const comp_height = comp.offsetHeight // 812
				const comp_top = comp_box.top;

				if(comp_top <= window_height){
					const value = -1 * (((250 / (window_height + comp_height)) * comp_top) + 125)
					image.style.top = value + 'px'
				}
			} */
			// END SCROLL PARALLAX

			// onMounted INTERCEPT
			observer.observe(target.value);

			// cols
			gsap.set(['.num_col1', '.num_col2', '.num_col3', '.ripple_button'], {opacity: 0, left: '0px', top: '50px'})


			// header
/* 			gsap.set(['.header_bold' + randNum,'.header_light' + randNum], {opacity: 0})

			// accordion content
			closed_text = new SplitText(ar[0], {type:"lines"})
			gsap.set(closed_text.lines, {opacity:0, y: '50px' })
			gsap.set(ar[1], {opacity:0, y: '50px' })
			
			// extra
			gsap.set(['.extraContent' + randNum], {opacity: 0, left: '-50px'})

			// image
			gsap.set('.ita_image' + randNum, {x: '-50px', opacity: 0 })

			// bg parallax
			if(background_image != ''){
				gsap.set('#parallax_image' + randNum, {opacity: 0})
			} */
		})

		return {
			target,
			isBtnOpen,
			changeAccordion,
			randNum

			// hide_image,

			// ita_headline_first,
			// ita_headline_second,

			// text_before_accordion,
			// text_accordion_pre_image,
			// text_accordion_image,
			// text_accordion_post_image,

			// image,
			// image_caption,
			// person_image,

			// background_color,
			// text_color,
			// background_image,
			// background_image_fullwidth,

			// target,
			// boldHeaderFirst,
			// useAccordion,
			// extraContent,
			// textToLeft
		}
	}
}
</script>
