<template>
	<section class="section_numbers section_numbers_two" ref="target" :id="'section_numbers_two' + randNum">
			
		<!-- parallax -->
		<picture class="parallax_container">
			<img :src="require(`@/assets/img/map_bg.png`)" 
				class="parallax_image animated_image text_to_left" 
				alt="Background image for decoration" 
				:id="'parallax_image' + randNum"
				>
		</picture>
		
		<div class="container">
			<div class="flex">
				<div class="flex-half numbers_intro flex-gutter-side">
					<h2>
						{{ $t('numberTwoCounter_header') }}
					</h2>
					<p>
						{{ $t('numberTwoCounter_text') }}
					</p>
				</div>
			</div>
			<!-- numbers -->
			<div class="flex align-start">
				
				<div class="grid grid_numbers flex-full">

					<div class="flex-third" v-for="(number, index) in numbers" :key="index">
						<div class="number_countainer" >
							<div class="number_counter2"
								:data-start_value="number.start_value"
								:data-end_value="number.end_value"
								:data-separator_en="number.separator_en"
								:data-separator_se="number.separator_se"
								:data-sep_pos="number.sep_pos"
								:data-leading_zero="number.leading_zero"
								:class="{hide: doHide}"
							> 
								{{number.end_value}} 
							</div>
							<div class="number_counter2_lang" :class="{show: doShow}">
								{{ $t('numberTwoCounter[' + index + ']') }}
							</div>
							<div class="number_counter2_text text-regular">
								{{ $t('numberTwoCounterText[' + index + ']') }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


	</section>
</template>

<script>
import { onMounted, onUpdated, ref } from 'vue'
import gsap from 'gsap'
import i18n from "@/i18n"

export default {
	name: 'NumbersTwo',
	setup(){
		const doHide = ref(false)
		const doShow = ref(false)
		const randNum = '_' + Math.floor(Math.random() * 1000000)

		const numbers = [
			{id: 1, start_value: 20, end_value: 66, separator_en: '', separator_se: '', sep_pos: 0, leading_zero: ''},
			{id: 2, start_value: 12, end_value: 59, separator_en: '', separator_se: '', sep_pos: 0, leading_zero: ''},
		]

		// INTERCEPT
		let onlyOnce = true;
		const target = ref();
		const observer = new IntersectionObserver(
			([entry]) => {
				if(entry.isIntersecting == true){

					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{ threshold: 0.3 }
		);

		// INTERCEPT ANIMATION
		const inView = () => {

			const items = document.querySelectorAll(".number_counter2");

			for(let i=0; i < items.length; i++){
				gsap.from(items[i], {
					textContent: items[i].dataset.start_value,
					duration: 5, // 4
					ease: 'circ.out',
					snap: { textContent: 1 },
					onComplete: function(){
						hideNumbers();
					},
					stagger: {
						each: 0.3,
						onUpdate: function() {
							this.targets()[0].innerHTML = numberWithCommas(Math.ceil(this.targets()[0].textContent), 
								this.targets()[0].dataset.separator_en,
								this.targets()[0].dataset.separator_se,
								this.targets()[0].dataset.sep_pos,
								this.targets()[0].dataset.leading_zero,
							);
						},
					} // stagger
				}); // gsap from
			
			} // for loop

			function numberWithCommas(x, separator_en, separator_se, sep_pos, leading_zero) {

				let newString = ''
				
				if(i18n.global.locale == 'se'){
					newString = leading_zero + x.toString().slice(0, sep_pos) + separator_se + x.toString().slice(sep_pos);
				}else{
					newString = leading_zero + x.toString().slice(0, sep_pos) + separator_en + x.toString().slice(sep_pos);
				}
				
				return newString;
			}
			let i = 0;
			const hideNumbers = () =>{
				i++
				if(i == 6){
					doHide.value = true
					doShow.value = true
				}
			}

			// splash
			// gsap.to('.number_splash', {duration: 1.8, delay: 0.0, scale: 1, ease: 'back.out'})

		} // end inView
		// END INTERCEPT

		onMounted(() => {

			const body = document.getElementsByTagName("BODY")[0];		
			const comp = document.getElementById('section_numbers_two' + randNum);
			const offset = comp.getBoundingClientRect();
			const top = offset.top;
			const height = comp.offsetHeight;

			// SCROLL PARALLAX
			const handleScroll = (evt) => {
				const window_height = window.innerHeight
				const image = document.getElementById('parallax_image' + randNum);
				const comp = document.getElementById('section_numbers_two' + randNum);
				const comp_box = comp.getBoundingClientRect();
				const comp_height = comp.offsetHeight // 812
				const comp_top = comp_box.top;

				if(comp_top <= window_height){
					const value = -1 * (((250 / (window_height + comp_height)) * comp_top) + 125)
					image.style.top = value + 'px'
				}
			}
			// END SCROLL PARALLAX

			// onMounted INTERCEPT
			observer.observe(target.value);
			document.addEventListener('scroll', handleScroll)
			handleScroll()
			
			// init gsap
			// gsap.set('.number_splash', {scale:0})

		})
		return {
			numbers,
			doHide,
			doShow,
			randNum,
			target
		}
	}
}
</script>

<style>
	.hide{
		display:none;
	}
	.number_counter2_lang{
		display:none;
	}
	.show{
		display:block;
	}
</style>