<template>
	<div class="accordion">
		<div class="closedAcc" v-html="textClosed"></div> 
		<div ref="openAcc" class="openAcc" :class="'openAcc' + randNum" v-if="useAccordion">
			
			<!-- pre text -->
			<span v-html="text_accordion_pre_image"></span>
			
			<!-- image -->
			<p v-if="person_image">
				<span class="image_person">
					<img :src="require(`@/assets/img/portraits/${person_image}`)" alt="">
					<span class="figcaption">{{image_caption}}</span>
				</span>


				{{text_accordion_image}}
			</p>
			<div class="image_person_mobile" v-if="person_image">
				<img :src="require(`@/assets/img/portraits/${person_image}`)" alt="">
				<span class="figcaption">{{image_caption}}</span>
			</div>

			<!-- post text -->
			<span v-html="text_accordion_post_image"></span>
		</div>
		
		<!-- button -->
		<a aria-label="Expand text to read more" class="accordion_button" @click="changeAccordion" :data-accstatus="false" v-if="useAccordion">
			<span class="btn_open"><span class="btn_text">{{ $t('btn_expander_open') }}</span> <span class="btn_icon open">+</span></span>
			<span class="btn_close"><span class="btn_text">{{ $t('btn_expander_close') }}</span> <span class="btn_icon close">-</span></span>
		</a>
	</div>
</template>

<script>
import { ref, onMounted } from 'vue'
import gsap from 'gsap'
import { accordionParallax } from '@/composables/parallax';

export default {
	name: 'Accordion',
	props: {
		useAccordion: Boolean,
		textClosed: Array,
		
		text_accordion_pre_image: String,
		text_accordion_image: String,
		text_accordion_post_image: String,

		person_image: String,
		image_caption: String
	},
	setup(props){
		const textClosedArr = props.textClosed
		const useAccordion = props.useAccordion

		const text_accordion_pre_image = props.text_accordion_pre_image
		const text_accordion_image = props.text_accordion_image
		const text_accordion_post_image = props.text_accordion_post_image

		const person_image = props.person_image
		const image_caption = props.image_caption

		let textClosed = ''
		let textOpen = ''
		const randNum = '_' + Math.floor(Math.random() * 1000000)

		let switchBtnText = false;

		// compile html string
		for(let i = 0; i < textClosedArr.length; i++){
			textClosed += textClosedArr[i]
		}

		// click accordion
		const changeAccordion = (event) => {
			if(event.target.dataset.accstatus == 'false'){
				event.target.dataset.accstatus = 'true';
				switchBtnText = !switchBtnText;				

				gsap.set('.openAcc' + randNum, {height:"auto"})
    			gsap.from('.openAcc' + randNum, {duration: 0.7, height:0,
					onComplete: function(){
						accordionParallax()
					}
				})

			}else{
				switchBtnText = !switchBtnText;
				event.target.dataset.accstatus = 'false';

				gsap.to('.openAcc' + randNum, {duration: 0.5, height:0,
					onComplete: function(){
						accordionParallax()
					}
				})
			}
		}

		onMounted(() => {
			// set acc body to 0
			if(useAccordion){
				gsap.set('.openAcc', {height:"0"})
			}
		})
		return{
			textClosed,
			useAccordion,
			randNum,
			switchBtnText,

			text_accordion_pre_image,
			text_accordion_image,
			text_accordion_post_image,

			person_image,
			image_caption,

			changeAccordion
		}
	}
}
</script>

<style>

</style>
