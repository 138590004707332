<template>
	<header>

		<nav id="nav">
			<div class="nav_container">
			<div class="nav_items">
			<div class="nav_inner">
				<ul class="menu menu_logo">
					<li class="nav_logo" @click="gotoTop">
						<div class="logo_images">
							<div class="logo_image logo_image_white"></div>
							<div class="logo_image logo_image_black"></div>
						</div>
					</li>
				</ul>

				<ul class="menu menu_desktop menu_left">
					<li>
						<a @click="scrollTo('.scroll_menu0', 0)">
							{{ $t('menu[0]') }}
						</a>
					</li>
					<li>
						<a @click="scrollTo('.scroll_menu1', 0)">
							{{ $t('menu[1]') }}
						</a>
					</li>
					<li>
						<a @click="scrollTo('.scroll_menu2', 0)">
							{{ $t('menu[2]') }}
						</a>
					</li>
					<li>
						<a @click="scrollTo('.scroll_menu3', 0)">
							{{ $t('menu[3]') }}
						</a>
					</li>
					<!-- <li>
						<a @click="scrollTo('.scroll_menu4', 0)">
							{{ $t('menu[4]') }}
						</a>
					</li> -->
				</ul>

				<!-- hamburger -->
				<div id="hamburger" @click="clickMenu">
					<span></span>
					<span></span>
					<span></span>
					<span></span>
				</div>

			</div>
			</div>
			</div>
		</nav>

		<!-- mobile menu  -->
		<nav>
			<div class="mobileMenu" id="mobileMenu" style="top:-110%;">

				<ul class="menu">
					<li>
						<a @click="scrollTo('.scroll_menu0', 0)">
							{{ $t('menu[0]') }}
						</a>
					</li>
					<li>
						<a @click="scrollTo('.scroll_menu1', 0)">
							{{ $t('menu[1]') }}
						</a>
					</li>
					<li>
						<a @click="scrollTo('.scroll_menu2', 0)">
							{{ $t('menu[2]') }}
						</a>
					</li>
					<li>
						<a @click="scrollTo('.scroll_menu3', 0)">
							{{ $t('menu[3]') }}
						</a>
					</li>
					<!-- <li>
						<a @click="scrollTo('.scroll_menu4', 0)">
							{{ $t('menu[4]') }}
						</a>
					</li> -->
				</ul>

			</div> 
			<!-- mobileMenu -->
		</nav>
	</header>
</template>


<script>
import { mounted, ref } from 'vue';

import gsap from 'gsap'
import {ScrollToPlugin} from '@/assets/gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'MainMenu',
  methods: {

	switchLocale(locale){
	  if( this.$i18n.locale !== locale){
		this.$i18n.locale = locale
		this.activeMenuSE = false
		this.activeMenuEN = false

		// close mobile menu
		document.getElementById('mobileMenu').style.top = '-110%'
		document.getElementById('hamburger').className = ""
	  }
	},

	// scroll to module when menu click
	scrollTo(el, num){
		
		// close mobile menu
		document.getElementById('mobileMenu').style.top = '-110%'
		document.getElementById('hamburger').className = ""

		const sect = document.querySelectorAll(el);
		const sect_box = sect[num].getBoundingClientRect();
		const top = sect_box.top + window.scrollY - 85;
		
		gsap.to(window, { duration: 1, scrollTo: top });

		// document.getElementById('nav').style.top='-64px';
	},

	gotoTop(){
		gsap.to(window, { duration: 1, scrollTo: 0 });

		// close mobile menu
		document.getElementById('mobileMenu').style.top = '-110%'
		document.getElementById('hamburger').className = ""
	},
	
	// hamburger
	clickMenu: function() {
		document.getElementById('hamburger').classList.toggle('open')

		if(document.getElementById('mobileMenu').style.top == '0px'){
			document.getElementById('mobileMenu').style.top = '-110%'
		}else{
			document.getElementById('mobileMenu').style.top = '0px'
		}		
	}
  },

  mounted(){
	let lastScrollTop = 208; // 232 // 182 // 208
	let nav = document.getElementById("nav");
	
	window.addEventListener("scroll", function(){  
		let st = window.pageYOffset || document.documentElement.scrollTop;

		if (lastScrollTop > st ){
			nav.classList.remove("isScrolled");
			// document.getElementById('nav').style.top = '-64px'
		} else {
			nav.classList.add("isScrolled");
			// document.getElementById('nav').style.top = '0px'
		}
		// lastScrollTop = st;
	}, false);

	// set language from url string /se /en
	const url = window.location.href;
	if(url.indexOf('/se') !== -1){
	  this.$i18n.locale = 'se'
	  this.activeMenuSE = true

	}else{
	  this.activeMenuEN = true
	}
  },
  data(){
	return {
		activeMenuSE: false,
		activeMenuEN: false,
		locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
	}
  }
}

</script>

<style scoped>

	.menu_left div, .menu_left a{
		cursor: pointer;
	}
	


  /* a{
	margin:0 10px;
  }
  .activeMenuLangItem{
	color: #42b983 !important;
  } */

</style>