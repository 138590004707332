import { createApp } from 'vue/dist/vue.esm-bundler';
import App from './App.vue'
import router from './router'
import i18n from './i18n'

import VueCookieComply from 'vue-cookie-comply'

import '@/assets/css/miltton.css'
import '@/assets/js/SplitText.min.js'

const app = createApp(App)

const rootComponent = app.use(i18n)
.use(VueCookieComply)
.use(router)
.mount('#app')
