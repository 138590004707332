<template>
	<section class="section_one_col" :style="{ color: text_color }" :class="component_class">
		<div :style="{ background: background_color, backgroundImage: 'url(' + background_image + ')' }" :class="'header_text-' + childrenClass" class="header_text" ref="target">
			<div class="container">
				
				<!-- icon -->
				<div v-if="icon" class="icon" :class="'icon' + randNum">
					<img :src="require(`@/assets/img/icons/${icon}`)">					
				</div>

				<h2 class="animated_header">
					<span :class="'header_bold' + randNum" class="header_bold" v-html="headline_first"></span>
					<span :class="'header_light' + randNum" class="header_light" v-html="headline_second"></span>
				</h2>
				
				<div class="flex" :class="'container-' + childrenClass">
					
					<div class="flex-child" :class="[childrenClass, 'flex-child' + randNum]">
						<span v-html="text_col1"></span>
					
					<Accordion 
					:useAccordion="useAccordion"

					:textClosed="[text_before_accordion]" 
					:text_accordion_pre_image = text_accordion_pre_image
					:text_accordion_image = text_accordion_image
					:text_accordion_post_image = text_accordion_post_image

					:person_image = person_image
					:image_caption = image_caption

					/>
					</div>

					<div class="flex-child" :class="[childrenClass, 'flex-child' + randNum]" v-if="num_cols > 1">
						<span v-html="text_col2"></span>
					</div>

					<div class="flex-child" :class="[childrenClass, 'flex-child' + randNum]" v-if="num_cols > 2">
						<span v-html="text_col3"></span>
					</div>

				</div> <!-- flex -->

				<!-- button -->
				<div v-if="btn_text" class="button" :class="'button' + randNum">
					<a :href="btn_url" target="_blank">{{btn_text}}</a>
				</div>

			</div> <!-- container -->
		</div> <!-- article -->

	</section>
</template>

<script>
	import { computed, ref, onMounted, onUnmounted } from 'vue'
	import gsap from 'gsap'
	import {SplitText} from '@/assets/js/SplitText.min.js';
	import Accordion from '@/components/2023/Accordion.vue'

	export default {
		name:'ArticleOneCol',
		props: {
			headline_first: String,
			headline_second: String,
			num_cols: Number,
			text_col1: String,
			text_col2: String,
			text_col3: String,
			background_color: String,
			background_image: String,
			text_color: String,

			useAccordion: Boolean,
			text_before_accordion: String,
			text_accordion_pre_image: String,
			text_accordion_image: String,
			text_accordion_post_image: String,
			person_image: String,
			image_caption: String,
			
			icon: String,

			btn_text: String,
			btn_url: String,

			component_class: String

		},
		components: {
			Accordion
		},
		setup(props){
			const randNum = '_' + Math.floor(Math.random() * 1000000)
			const num_cols = props.num_cols
			const childrenClass = 'flex-child-count' + num_cols
			
			const headline_first = props.headline_first
			const headline_second = props.headline_second

			// const header = props.header
			const text_col1 = props.text_col1
			const text_col2 = props.text_col2
			const text_col3 = props.text_col3
			const background_color = props.background_color
			const background_image = props.background_image
			const text_color = props.text_color
			
			const btn_text = props.btn_text
			const btn_url = props.btn_url
			
			const icon = props.icon

			const component_class = props.component_class

			// accordion
			const useAccordion = props.useAccordion
			const text_before_accordion = props.text_before_accordion
			const text_accordion_pre_image = props.text_accordion_pre_image
			const text_accordion_image = props.text_accordion_image
			const text_accordion_post_image = props.text_accordion_post_image
			const person_image = props.person_image
			const image_caption = props.image_caption

			// collect accordion elements for animation
			let ar = []
			if(useAccordion == true){
				ar = ['.ita_content' + randNum + ' .closedAcc .ita_text1', '.ita_content' + randNum + ' .accordion_button' ]
			}else{
				ar = ['.ita_content' + randNum + ' .closedAcc .ita_text1' ]
			}

			// INTERCEPT
			let onlyOnce = true;
			const target = ref();
			const observer = new IntersectionObserver(
				([entry]) => {
					if(entry.isIntersecting == true){

						if(onlyOnce == true){
							onlyOnce = false;
							inView();
						}
					}
				},
				{ threshold: 0.5 }
				);

			// INTERCEPT ANIMATION
			const inView = () => {

				// SPLIT TEXT
				// type:"words, lines, chars"
				// postion: "relative" = default || postion: "absolute" || obs absolute pajjar text flow vid minskning i size

				// header
				const header_bold = new SplitText('.header_bold' + randNum, {type:"words"})
				const header_light = new SplitText('.header_light' + randNum, {type:"words"})
				gsap.set(header_bold.words, {opacity: 0, x: '0', y: '30', scale: 1})
				gsap.set(header_light.words, {opacity: 0, x: '-50', y: '0', scale: 1})
				gsap.set(['.header_bold' + randNum,'.header_light' + randNum], {opacity: 1})

				for(let i = 0; i < header_bold.words.length; i++){
					gsap.to(header_bold.words[i], {
						duration: 0.7,
						opacity: 1,
						x: '0',
						y: 0,
						scale: 1,
						ease: 'power2.out',
						delay: 0.5 + (i * 0.05	)
					})
				}

				// header_light loop
				for(let i = 0; i < header_light.words.length; i++){
					gsap.to(header_light.words[i], {
						duration: 0.7,
						opacity: 1,
						x: '0',
						y: 0,
						scale: 1,
						ease: 'power2.out',
						delay: 0.9 + (i * 0.05	)
					})
				}

				// text
				gsap.to('.flex-child' + randNum, {
					duration: 1,
					y: '0',
					x: '0',
					opacity: 1,
					ease: 'Sine.out',
					stagger: 0.15,
					delay: 0.3
				})

				// button
				if (btn_text != '' ) {	
					gsap.set('.button' + randNum, {opacity: 0, x: '0', y: '30', scale: 1})

					gsap.to('.button' + randNum, {
						duration: 1,
						y: '0',
						x: '0',
						opacity: 1,
						ease: 'Sine.out',
						delay: 0.4
					})
				}

				// icon
				if (icon != '' ) {	
					gsap.set('.icon' + randNum, {opacity: 0, x: '0', y: '40', scale: 1})

					gsap.to('.icon' + randNum, {
						duration: 1,
						y: '0',
						x: '0',
						opacity: 1,
						ease: 'Sine.out',
						delay: 0.2
					})
				}

			}
			// END INTERCEPT

			onMounted(()=>{

			// onMounted INTERCEPT
				observer.observe(target.value);
				
			// hide things
				gsap.set(['.header_bold' + randNum,'.header_light' + randNum], {opacity: 0})
				gsap.set(['.flex-child' + randNum], {opacity: 0, y: '50px'})
				
			})

			return {
				num_cols,
				childrenClass,
				target,
				randNum,
				headline_first,
				headline_second,
				
				text_col1,
				text_col2,
				text_col3,
				
				background_color,
				background_image,
				text_color,
				
				icon,

				component_class,
				
				btn_text,
				btn_url,

				useAccordion,
				text_before_accordion,
				text_accordion_pre_image,
				text_accordion_image,
				text_accordion_post_image,
				image_caption,
				person_image,
			}

		}
	}
</script>

<style scoped>

</style>