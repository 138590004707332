import { computed, ref } from 'vue';
import gsap from 'gsap'

export function accordionParallax() {

	// get all components with parallax
	let section_parallax = document.getElementsByClassName("parallax_container");
	const window_height = window.innerHeight
	
	// loop components
	for(let i = 0; i < section_parallax.length; i++){
		let animated_image = section_parallax[i].getElementsByClassName("animated_image")
		const image = animated_image[0]
		const comp = section_parallax[i]
		const comp_box = comp.getBoundingClientRect();
		const comp_height = comp.offsetHeight // 812
		const comp_top = comp_box.top;
		
		const value = -1 * (((250 / (window_height + comp_height)) * comp_top) + 125)

		// animate element
		gsap.to(animated_image[0], {
			duration: 0.3,
			ease: 'power1.out',
			top: value
		})
	} // end loop

	return {
	
	}
}