<template>
	<section>
		<div class="section_start" ref="target" :id="'section_start' + randNum"
		 :style="{ background: background_color }">	
			<div class="container">

				<div class="inner_content">
					<h2 class="animated_header" :class="'animated_header' + randNum">
						<span :class="'header_text' + randNum" class="header_text" v-html="headline" 
						:style="{ color : text_color, borderColor : text_color }"></span>

						<div class="header_line" :class="'header_line' + randNum" :style="{ background : text_color }"></div>
					</h2>
				</div>
				
			</div>
		</div>
	</section>
</template>

<script>

import { computed, ref, onMounted, onUnmounted } from 'vue'
import gsap from 'gsap'
import {SplitText} from '@/assets/js/SplitText.min.js';

export default {
	name:'SectionHeader',
	props: {
		background_color: String,
		text_color: String,
		section_start_header: String
	},
	components: {
	},
	setup(props){
		const background_color = props.background_color
		const text_color = props.text_color
		const headline = props.section_start_header
		const randNum = '_' + Math.floor(Math.random() * 1000000)

		// INTERCEPT
		let onlyOnce = true;
		const target = ref();
		const observer = new IntersectionObserver(
			([entry]) => {
				if(entry.isIntersecting == true){

					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{ threshold: 0.7 }
		);

		// INTERCEPT ANIMATION
		const inView = () => {

			// SPLIT TEXT
			// type:"words, lines, chars"
			// postion: "relative" = default || postion: "absolute" || obs absolute pajjar text flow vid minskning i size

			// decoration
			// gsap.set('.header_decoration' + randNum, {scaleX: '0%', x: '-50px', transformOrigin: '0% 100%'})
			// gsap.to('.header_decoration' + randNum, {duration: 0.7, x: '0', opacity: '1', scaleX: '100%', ease: 'power2.out', delay: 0.3})
			
			gsap.to('.header_line' + randNum, {
					duration: .5,
					opacity: 1,
					x: '0',
					scaleX: 1,
					y: 0,
					scale: 1,
					ease: 'power1.out',
					delay: 0
			})

			// text
			const header_text = new SplitText('.header_text' + randNum, {type:"words"})
			gsap.set('.header_text' + randNum, {opacity: 1});
			gsap.set(header_text.words, {opacity: 0, x: '0', y: '30', opacity: 0, scale: 1})

			for(let i = 0; i < header_text.words.length; i++){
				gsap.to(header_text.words[i], {
					duration: 0.7,
					opacity: 1,
					x: '0',
					y: 0,
					scale: 1,
					ease: 'power2.out',
					delay: 0.5 + (i * 0.05	)
				})
			}
		}
		// END INTERCEPT

		onMounted(()=>{
			const body = document.getElementsByTagName("BODY")[0];		
			const comp = document.getElementById('section_start' + randNum);
			const offset = comp.getBoundingClientRect();
			const top = offset.top;
			const height = comp.offsetHeight;

			gsap.set('.header_line' + randNum, {opacity: 0, scaleX: 0.5});

			// onMounted INTERCEPT
			observer.observe(target.value);
		})

		return {
			randNum,
			headline,
			background_color,
			text_color,
			target
		}
	}
}
</script>
