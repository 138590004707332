<template>
	<section class="section_numbers" ref="target">
		<div class="container">

			<div class="flex intro_text">
				<div class="flex-half numbers_intro flex-gutter-side">
					<h2 class="numbers_header">
						{{ $t('numberSixCounter_header') }}
					</h2>
					<p class="numbers_text">
						{{ $t('numberSixCounter_text') }}
					</p>
				</div>
				<div class="flex-half flex-gutter-side numbers_icon">
					<img :src="require(`@/assets/img/icons/numbers_icon.svg`)" alt="Counter Icon" class="numbers_icon_img">

				</div>

			</div>
			<!-- numbers -->
			<div class="flex align-start">

				<!-- <div class="flex flex-three-quarter"> -->
				
				
				<div class="grid grid_numbers flex-full">


					<div class="flex-third" v-for="(number, index) in numbers" :key="index">
						<div class="number_countainer" >
							<div class="number_counter"
								:data-start_value="number.start_value"
								:data-end_value="number.end_value"
								:data-separator_en="number.separator_en"
								:data-separator_se="number.separator_se"
								:data-sep_pos="number.sep_pos"
								:data-leading_zero="number.leading_zero"
								:class="{hide: doHide}"
							> 
								{{number.end_value}} 
							</div>
							<div class="number_counter_lang" :class="{show: doShow}">
								{{ $t('numberSixCounter[' + index + ']') }}
							</div>
							<div class="number_counter_text">
								{{ $t('numberSixCounterText[' + index + ']') }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


	</section>
</template>

<script>
import { onMounted, onUpdated, ref } from 'vue'
import gsap from 'gsap'
import i18n from "@/i18n"

export default {
	name: 'NumbersSix',
	setup(){
		const doHide = ref(false)
		const doShow = ref(false)

		const numbers = [
			{id: 1, start_value: 100, end_value: 169, separator_en: '', separator_se: '', sep_pos: 0, leading_zero: ''},
			{id: 2, start_value: 8, end_value: 31, separator_en: '', separator_se: '', sep_pos: 0, leading_zero: ''},
			{id: 3, start_value: 1, end_value: 22, separator_en: '', separator_se: '', sep_pos: 0, leading_zero: ''},
			{id: 4, start_value: 50, end_value: 78, separator_en: '', separator_se: '', sep_pos: 0, leading_zero: ''},
			{id: 5, start_value: 102, end_value: 108, separator_en: '', separator_se: '', sep_pos: 0, leading_zero: ''}, // add 0 to start
			{id: 6, start_value: 8, end_value: 30, separator_en: '', separator_se: '', sep_pos: 0, leading_zero: ''}
		]

		// INTERCEPT
		let onlyOnce = true;
		const target = ref();
		const observer = new IntersectionObserver(
			([entry]) => {
				if(entry.isIntersecting == true){

					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{ threshold: 0.3 }
		);

		// INTERCEPT ANIMATION
		const inView = () => {

			const items = document.querySelectorAll(".number_counter");

			for(let i=0; i < items.length; i++){
				gsap.from(items[i], {
					textContent: items[i].dataset.start_value,
					duration: 5, // 4
					ease: 'circ.out',
					snap: { textContent: 1 },
					onComplete: function(){
						hideNumbers();
					},
					stagger: {
						each: 0.3,
						onUpdate: function() {
							this.targets()[0].innerHTML = numberWithCommas(Math.ceil(this.targets()[0].textContent), 
								this.targets()[0].dataset.separator_en,
								this.targets()[0].dataset.separator_se,
								this.targets()[0].dataset.sep_pos,
								this.targets()[0].dataset.leading_zero,
							);
						},
					} // stagger
				}); // gsap from
			
			} // for loop

			function numberWithCommas(x, separator_en, separator_se, sep_pos, leading_zero) {

				let newString = ''
				
				if(i18n.global.locale == 'se'){
					newString = leading_zero + x.toString().slice(0, sep_pos) + separator_se + x.toString().slice(sep_pos);
				}else{
					newString = leading_zero + x.toString().slice(0, sep_pos) + separator_en + x.toString().slice(sep_pos);
				}
				
				return newString;
			}
			let i = 0;
			const hideNumbers = () =>{
				i++
				if(i == 6){
					doHide.value = true
					doShow.value = true
				}
			}

			// intro + icon
			gsap.to('.numbers_header', {
				duration: 1.0,
				y: 0,
				x: 0,
				opacity: 1,
				ease: 'power2.out',
				delay: 0.0
			})

			gsap.to('.numbers_text', {
				duration: 1.0,
				y: 0,
				x: 0,
				opacity: 1,
				ease: 'power2.out',
				delay: 0.4
			})

			gsap.to('.numbers_icon_img', {
				duration: 1.0,
				y: 0,
				x: 0,
				opacity: 1,
				ease: 'power2.out',
				delay: 0.2
			})

		} // end inView
		// END INTERCEPT

		onMounted(() => {

			// onMounted INTERCEPT
			observer.observe(target.value);

			// init gsap
			// gsap.set('.number_splash', {scale:0})
			gsap.set(['.numbers_icon_img', '.numbers_header', '.numbers_text'], {opacity: 0, x: '0', y: '30', scale: 1})

		})
		return {
			numbers,
			doHide,
			doShow,
			target
		}
	}
}
</script>

<style>
	.hide{
		display:none;
	}
	.number_counter_lang{
		display:none;
	}
	.show{
		display:block;
	}
</style>