<template>
	<section class="section_journey" ref="target">
		<div class="half_background">
			<div class="left_background_grid journey_background_grid"></div>
			
			<div class="journey_head_content">
				<div class="content">
					<h2>Timeline: Cass Materials</h2>
				</div>
			</div>
			
			<div id="journey" v-bind:class="{isMobile: isMobile}" >
				
				<div class="journey_container">

				<div class="ratio_outer">
					<div class="ratio_inner">


					<div class="inner_container">
						<div v-for="(item, index) in Object.keys($i18n.messages[$i18n.fallbackLocale].journey.slides)" 
							:key="index"
							:class="'slide slide' + index + ' ' + $t('journey.slides[' + index + '].design')"
						>
							<div class="slide_inner">
								<div class="slide_background"></div>
								
								<!-- content -->
								<div class="slide_content">
									
									<!-- icon -->
									<div class="slide_icon" 
										v-if="$t('journey.slides[' + index + '].icon')"
									>
										<img :src="require(`@/assets/img/${$t('journey.slides[' + index + '].icon')}`)">
									</div>

									<!-- year -->
									<span class="slide_year" 
										v-if="$t('journey.slides[' + index + '].year')"
									>
										{{ $t('journey.slides[' + index + '].year') }} 
									</span>

									<!-- header -->
									<h3 class="slide_header" 
										v-if="$t('journey.slides[' + index + '].header')"
									>
										{{ $t('journey.slides[' + index + '].header') }} 
									</h3>

									<!-- text -->
									<p class="slide_text" 
										v-if="$t('journey.slides[' + index + '].text')"
									>
										{{ $t('journey.slides[' + index + '].text') }} 
									</p>
								</div>
								
								<div class="slide_path_image"></div>
							
							</div> <!-- end slide_item_inner -->
						</div> <!-- end loop -->
					</div>
				
					</div> <!-- ratio_inner -->
				</div> <!-- ratio_outer -->

					<!-- nav arrows -->

					<div @click="next" class="slider_next">
						<div class="icon"></div>
					</div>

					<div @click="prev" class="slider_prev">
						<div class="icon"></div>
					</div>
					
					<!-- end nav arrows -->

				</div> <!-- end journey_container -->
			</div> <!-- end #journey -->

			<!-- bullits timeline -->
			<div class="timeline">
				<div class="timeline_inner">
					<div class="timeline_start"></div>
					<div class="bullits">
						<div v-for="(item, index) in Object.keys($i18n.messages[$i18n.fallbackLocale].journey.slides)" 
									:key="index"
									:class="'bullit bullit' + index"
									@click="navigation(index, 'manual')"
								>
							<div class="bullit_inner"
							:class="[{active : index === counter}, 'slider_nav_item' + randNum + index]"
							></div>
						</div>
					</div>
					<div class="timeline_end"></div>
					<div class="timeline_path"></div>
				</div>
			</div>
			<!-- end bullits timeline -->

		</div> <!-- bg grid -->

	</section>
</template>

<script>
import { ref, onMounted } from 'vue'
import gsap from 'gsap'

export default {
	name: 'Journey',
	props:{
		
	},
	setup(props){
		const counter = ref(0)
		const randNum = '_' + Math.floor(Math.random() * 1000000) + '_'
		const numSlides = 9 // 1 mindre än antalet
		let leaveSlideNumber = 0
		let enterSlideNumber = 1
		let isTweening = false
		let slideLeftPercentage = 50 // mobile 100 desktop 50

		// NEXT
		const next = () => {
			// no tween in action
			if (!isTweening) {
				isTweening = true
				counter.value++
				let slides = document.querySelectorAll(".slide");

				if(counter.value >= numSlides && isMobile.value == false || counter.value > numSlides && isMobile.value == true){
					counter.value = 0

					// Loop back to beginning
					for(let i = 0; i < slides.length; i++){
						gsap.to(slides[i], {
							duration:1.5,
							left: i * slideLeftPercentage + '%',
							onComplete: tweenComplete,
							ease: 'power2.out'
						})
					}

				}else{
					gsap.to(slides, {
						duration:1.5,
						left: '-=' + slideLeftPercentage + '%',
						onComplete: tweenComplete,
						ease: 'power2.out'
					})
				}

			} // end tweening
		}

		// PREV
		const prev = () => {
			// no tween in action
			if (!isTweening) {
				isTweening = true

				counter.value--
				let slides = document.querySelectorAll(".slide");

				if( counter.value < 0){

					if (isMobile.value == true) {
						counter.value = numSlides
					}else{
						counter.value = (numSlides - 1)
					}
				
					// Loop to end slide
					let a = 0;
					Object.keys(slides).reverse()
					.forEach(function(index) {
						a++;
						gsap.to(slides[index], {
							duration:1.5,
							left: 100 - (a * slideLeftPercentage) + '%',
							onComplete: tweenComplete,
							ease: 'power2.out'
						})
					});

				// not start slide - regular move
				}else{
					gsap.to(slides, {
						duration:1.5,
						left: '+=' + slideLeftPercentage + '%',
						onComplete: tweenComplete,
						ease: 'power2.out'
					})
				}
			} // end tweening
			
		}

		const gotoSlide = (num) => {
			counter.value = num
		}

		// reset tweening
		const tweenComplete = () => {
			isTweening = false
		}
		
		// intercept 
		let onlyOnce = true;
		const target = ref();
		const observer = new IntersectionObserver(
			([entry]) => {
				// animate.value = entry.isIntersecting;
				if(entry.isIntersecting == true){

					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{
				threshold: 0.3
			}
		);

		// INTERCEPT
		const inView = () => {
			isTweening = false

			// header
			/* gsap.to('.content h2', {
				duration: 1.0,
				y: 0,
				x: 0,
				opacity: 1,
				ease: 'power2.out',
				delay: 0.2
			}) */

		}
		// END INTERCEPT

		// BULLIT CLICK
		const navigation = (index, type) => {
			
			// no tween in action
			if (!isTweening) {
				
				// only wait if manual click
				if(type != 'automatic'){
					isTweening = true
				}

				let slides = document.querySelectorAll(".slide");
				let b = 0;
				let start_left = (index * slideLeftPercentage) * -1;
				
				// MOBILE
				if(isMobile.value){
					if (index == slides.length) {
						start_left = start_left + slideLeftPercentage;
					}

				}else{
				// DESKTOP 
				// last slide place to the right
					if (index == (slides.length - 1) ) {
						start_left = start_left + slideLeftPercentage;
					}
				}

				for (let i = 0; i < slides.length; i++) {
					gsap.to(slides[i], {
						duration:1.5,
						left: start_left + (b * slideLeftPercentage) + '%',
						onComplete: tweenComplete,
						ease: 'power2.out'
					})
					
					b++;
				}
				counter.value = index

			} // end tweening

		}

		// RESIZE WINDOW CALC
		let isMobile = ref(0)
		
		const windowResize = () => {
			let w = window.innerWidth;

			// break point
			if(w < 1000){
				isMobile.value = true;
				slideLeftPercentage = 100 // mobile 100 desktop 50
				calcSlides();

			}else{
				isMobile.value = false;
				slideLeftPercentage = 50 // mobile 100 desktop 50
				calcSlides();
			}

		} // resize

		// order slides if
		// desktop -> mobile -> desktop
		let deviceChange = false;
		
		const calcSlides = () => {
			if (isMobile.value != deviceChange) {
				deviceChange = isMobile.value
				navigation(counter.value, 'automatic')
			}

		} // calcSlides

		windowResize();

		onMounted(() => {

			// intercept 
			observer.observe(target.value);

			window.addEventListener("resize", windowResize);
			// gsap.set(['.content p'], {opacity: 0, x: '0', y: '30', scale: 1})

		})
		return{
			counter,
			randNum,
			
			next,
			prev,
			gotoSlide,

			windowResize,
			isMobile,
			navigation,

			target
		}
	}
}

</script>