<template>
	<section class="section_image_bg"  :style="{ background: background_color }">
		<div class="image_bg_content" ref="target" :id="'ita' + randNum" :style="{ background: background_color}" :class="{pull_image: textToLeft}">

			<!-- parallax -->
			<div class="parallax_container" v-if="background_image">
				<div
					class="parallax_image animated_image" 
					alt="Background image for decoration" 
					:id="'parallax_image' + randNum"
					:class="{text_to_left: textToLeft, fullwidth: background_image_fullwidth}"
					>
					<div class="inner_bg_repeat" :style="{ backgroundImage: 'url(' + require(`@/assets/img/bgs/grids/${background_image}`) + ')' }"></div>
				</div>
			</div>

			<div class="container"> 
				<div class="flex">
					<img :src="require(`@/assets/img/${image}`)" class="ita_image" alt="" :id="'ita_image' + randNum" :class="'ita_image' + randNum">
				</div> <!-- flex -->

			</div> <!-- ita container -->
		</div>
	</section>
</template>

<script>
// ImageTextAccordion = ita
import { computed, ref, onMounted, onUnmounted } from 'vue'
import gsap from 'gsap'

export default {
	name:'ImageBg',
	props: {
		background_image: String,
		background_image_fullwidth: Boolean,
		background_color: String,
		image: String,
		textToLeft: Boolean
	},
	components: {
	},
	setup(props){
		const randNum = '_' + Math.floor(Math.random() * 1000000)
		
		const background_image = props.background_image
		const background_image_fullwidth = props.background_image_fullwidth
		const background_color = props.background_color
		const image = props.image		
		const target = ref();
		const textToLeft = props.textToLeft

		// INTERCEPT
		let onlyOnce = true;
		const observer = new IntersectionObserver(
			([entry]) => {
				if(entry.isIntersecting == true){
					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{ threshold: 0.5 } // 0.7
			);

		// INTERCEPT ANIMATION
		const inView = () => {

			// image
			gsap.to('.ita_image' + randNum, {
				duration: 1.0,
				y: 0,
				x: '0px',
				opacity: 1,
				ease: 'power2.out',
				delay: 0
			})

			// parallax_image
			if(background_image != ''){
				gsap.to('#parallax_image' + randNum, {
					duration: 0.7,
					opacity: 0.3,
					delay: 0
				})
			}

		}
		// END INTERCEPT

		onMounted(()=>{
			const body = document.getElementsByTagName("BODY")[0];		
			const comp = document.getElementById('ita' + randNum);
			const offset = comp.getBoundingClientRect();
			const top = offset.top;
			const height = comp.offsetHeight;

			// SCROLL PARALLAX
			const handleScroll = (evt) => {
				const window_height = window.innerHeight
				const image = document.getElementById('parallax_image' + randNum);
				const comp = document.getElementById('ita' + randNum);
				const comp_box = comp.getBoundingClientRect();
				const comp_height = comp.offsetHeight // 812
				const comp_top = comp_box.top;

				if(comp_top <= window_height){
					const value = -1 * (((250 / (window_height + comp_height)) * comp_top) + 125)
					image.style.top = value + 'px'
				}
			}
			// END SCROLL PARALLAX

			// onMounted INTERCEPT
			observer.observe(target.value);
			if(background_image != ''){
				document.addEventListener('scroll', handleScroll)
				handleScroll()
			}

			// image
			gsap.set('.ita_image' + randNum, {x: '-50px', opacity: 0 })

			// bg parallax
			if(background_image != ''){
				gsap.set('#parallax_image' + randNum, {opacity: 0})
			}

		})

		return {
			image,
			background_color,
			background_image,
			background_image_fullwidth,

			target,
			randNum,
			textToLeft
		}
	}
}
</script>

<style scoped>

</style>