<template>
	
	<MainMenu />
	<Logos />
	<router-view/>
	
</template>

<script>
document.title = "IB:Accel | Project report 2022";

import Logos from '@/components/2023/Logos.vue'
import MainMenu from '@/components/2023/MainMenu.vue'

export default {

	name: 'App',
	components: {
		Logos,
		MainMenu
	},
	data(){
		return{

		}
	}
}
</script>