<template>

	<section class="section_article" :class="extraClass" :style="{ color: text_color }">

		<div class="image_text_accordion" ref="target" :id="'ita' + randNum" :style="{ background: background_color}" :class="{pull_image: textToLeft}">

			<!-- parallax -->
			<div class="parallax_container" v-if="background_image">
				<div
					class="parallax_image animated_image" 
					:id="'parallax_image' + randNum"
					:class="{text_to_left: textToLeft, fullwidth: background_image_fullwidth, displayNone: hide_image}"
					>
					<div class="inner_bg_repeat" :style="{ backgroundImage: 'url(' + require(`@/assets/img/bgs/grids/${background_image}`) + ')' }"></div>
				</div>
			</div>

			<div class="container"> 
				<div class="flex">
					<div class="ita-image-col ita-image-col-right flex-half" v-if="!textToLeft">
						<img v-if="image" :src="require(`@/assets/img/${image}`)" class="ita_image" alt="" :id="'ita_image' + randNum" :class="'ita_image' + randNum">
					</div>

					<div class="ita_content flex-half" :class="'ita_content' + randNum">

						<h2 class="animated_header">
							<span v-if="boldHeaderFirst">
								<span :class="'header_bold' + randNum" class="header_bold" v-html="ita_headline_first"></span>
								<span :class="'header_light' + randNum" class="header_light" v-html="ita_headline_second"></span>
							</span>

							<span v-if="!boldHeaderFirst">
								<span :class="'header_bold' + randNum" class="header_light" v-html="ita_headline_first"></span>
								<span :class="'header_light' + randNum" class="header_bold" v-html="ita_headline_second"></span>
							</span>

						</h2>

						<Accordion 
						:useAccordion="useAccordion"

						:textClosed="[text_before_accordion]" 
						:text_accordion_pre_image = text_accordion_pre_image
						:text_accordion_image = text_accordion_image
						:text_accordion_post_image = text_accordion_post_image

						:person_image = person_image
						:image_caption = image_caption

						/>
					</div>

					<div class="ita-image-col ita-image-col-left flex-half" v-if="textToLeft">
						<img v-if="image" :src="require(`@/assets/img/${image}`)" class="ita_image" alt="Article image for decoration" :id="'ita_image' + randNum" :class="'ita_image' + randNum">
					</div>

				</div> <!-- flex -->

				<span class="extraContent" :class="'extraContent' + randNum" v-html="extraContent"></span>

			</div> <!-- ita container -->
		</div>
	</section>
</template>

<script>
// ImageTextAccordion = ita
import { computed, ref, onMounted, onUnmounted } from 'vue'
import Accordion from '@/components/2023/Accordion.vue'
import gsap from 'gsap'
import {SplitText} from '@/assets/js/SplitText.min.js';

export default {
	name:'Article',
	props: {
		background_image: String,
		background_image_fullwidth: Boolean,
		background_color: String,
		text_color: String,

		text_before_accordion: String,
		text_accordion_pre_image: String,
		text_accordion_image: String,
		text_accordion_post_image: String,

		ita_headline_first: String,
		ita_headline_second: String,

		image: String,
		person_image: String,
		image_caption: String,

		boldHeaderFirst: Boolean,
		useAccordion: Boolean,
		extraContent: String,

		textToLeft: Boolean,
		extraClass: String
	},
	components: {
		Accordion
	},
	setup(props){
		const ita_headline_first = props.ita_headline_first
		const ita_headline_second = props.ita_headline_second
		
		const background_image = props.background_image
		const background_image_fullwidth = props.background_image_fullwidth
		const background_color = props.background_color
		const text_color = props.text_color

		const text_before_accordion = props.text_before_accordion
		const text_accordion_pre_image = props.text_accordion_pre_image
		const text_accordion_image = props.text_accordion_image
		const text_accordion_post_image = props.text_accordion_post_image

		const image = props.image		
		const person_image = props.person_image
		const image_caption = props.image_caption
		
		const extraContent = props.extraContent
		const randNum = '_' + Math.floor(Math.random() * 1000000)
		
		const target = ref();
		const useAccordion = props.useAccordion
		const boldHeaderFirst = props.boldHeaderFirst
		const textToLeft = props.textToLeft
		
		const extraClass = props.extraClass

		let closed_text
		let hide_image = ref(true) // used for mobile inview (smaller height)

		// collect accordion elements for animation
		let ar = []
		if(useAccordion == true){
			ar = ['.ita_content' + randNum + ' .closedAcc .ita_text1', '.ita_content' + randNum + ' .accordion_button' ]
		}else{
			ar = ['.ita_content' + randNum + ' .closedAcc .ita_text1' ]
		}

		// INTERCEPT
		let onlyOnce = true;
		const observer = new IntersectionObserver(
			([entry]) => {
				if(entry.isIntersecting == true){
					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{ threshold: 0.5 } // 0.7
			);

		// INTERCEPT ANIMATION
		const inView = () => {
			
			hide_image.value = false
			
			// 2 cols - image delay = 1 (after component header)
			// responsive - 1 col - image delay = 0 (before component header)
			let image_col_delay = 1.0
			if( window.innerWidth < 900){
				image_col_delay = 0
			}

			// SPLIT TEXT
			// type:"words, lines, chars"
			// postion: "relative" = default || postion: "absolute" || obs absolute pajjar text flow vid minskning i size

			// header
			const header_bold = new SplitText('.header_bold' + randNum, {type:"words"})
			const header_light = new SplitText('.header_light' + randNum, {type:"words"})
			gsap.set(header_bold.words, {opacity: 0, x: '0', y: '30', scale: 1})
			gsap.set(header_light.words, {opacity: 0, x: '-50', y: '0', scale: 1})
			gsap.set(['.header_bold' + randNum,'.header_light' + randNum], {opacity: 1})

			
			for(let i = 0; i < header_bold.words.length; i++){
				gsap.to(header_bold.words[i], {
					duration: 0.7,
					opacity: 1,
					x: '0',
					y: 0,
					scale: 1,
					ease: 'power2.out',
					delay: 0.3 + (i * 0.05	)
				})
			}

			// header_light loop
			for(let i = 0; i < header_light.words.length; i++){
				gsap.to(header_light.words[i], {
					duration: 0.7,
					opacity: 1,
					x: '0',
					y: 0,
					scale: 1,
					ease: 'power2.out',
					delay: 0.7 + (i * 0.05	)
				})
			}

			// Intro text
			for(let i = 0; i < closed_text.lines.length; i++){
				gsap.to(closed_text.lines[i], {
					duration: 1.2,
					y: '0px',
					opacity: 1,
					ease: 'power2.out',
					delay: 1.2 + (i * 0.1)
				})
			}

			// accodion closed elements
			// if have accordion
			if(useAccordion == true){

				// read more
				gsap.to(ar[1], {
					duration: 1.0,
					y: '0px',
					x: '0px',
					opacity: 1,
					ease: 'power2.out',
					delay: 1.3
				})
			}

			// image
			if(image != ''){
				gsap.to('.ita_image' + randNum, {
					duration: 1.0,
					y: 0,
					x: '0px',
					opacity: 1,
					ease: 'power2.out',
					delay: image_col_delay
				})
			}

			// extra content
			gsap.to('.extraContent' + randNum, {
				duration: 1.0,
				y: 0,
				left: 0,
				opacity: 1,
				ease: 'power2.out',
				delay: 1.3
			})

			// parallax_image
			if(background_image != ''){
				gsap.to('#parallax_image' + randNum, {
					duration: 0.7,
					opacity: 0.3,
					delay: 0
				})
			}

		}
		// END INTERCEPT

		onMounted(()=>{
			const body = document.getElementsByTagName("BODY")[0];		
			const comp = document.getElementById('ita' + randNum);
			const offset = comp.getBoundingClientRect();
			const top = offset.top;
			const height = comp.offsetHeight;

			// SCROLL PARALLAX
			const handleScroll = (evt) => {
				const window_height = window.innerHeight
				const image = document.getElementById('parallax_image' + randNum);
				const comp = document.getElementById('ita' + randNum);
				const comp_box = comp.getBoundingClientRect();
				const comp_height = comp.offsetHeight // 812
				const comp_top = comp_box.top;

				if(comp_top <= window_height){
					const value = -1 * (((250 / (window_height + comp_height)) * comp_top) + 125)
					image.style.top = value + 'px'
				}
			}
			// END SCROLL PARALLAX

			// onMounted INTERCEPT
			observer.observe(target.value);
			if(background_image != ''){
				document.addEventListener('scroll', handleScroll)
				handleScroll()
			}

			// header
			gsap.set(['.header_bold' + randNum,'.header_light' + randNum], {opacity: 0})

			// accordion content
			closed_text = new SplitText(ar[0], {type:"lines"})
			gsap.set(closed_text.lines, {opacity:0, y: '50px' })
			
			if(useAccordion == true){
				gsap.set(ar[1], {opacity:0, y: '50px' })
			}
			
			// extra
			gsap.set(['.extraContent' + randNum], {opacity: 0, left: '-50px'})

			// image
			if(image != ''){
				gsap.set('.ita_image' + randNum, {x: '-50px', opacity: 0 })
			}
			// bg parallax
			if(background_image != ''){
				gsap.set('#parallax_image' + randNum, {opacity: 0})
			}

		})

		return {
			hide_image,

			ita_headline_first,
			ita_headline_second,

			text_before_accordion,
			text_accordion_pre_image,
			text_accordion_image,
			text_accordion_post_image,

			image,
			image_caption,
			person_image,

			background_color,
			text_color,
			background_image,
			background_image_fullwidth,

			target,
			boldHeaderFirst,
			randNum,
			useAccordion,
			extraContent,
			textToLeft,

			extraClass
		}
	}
}
</script>

<style scoped>

</style>