<template>
	<div class="logos_container">
		<div class="logos" ref="target" :id="'logos' + randNum">
			<div class="logo" :class="'logo' + randNum"><img :src="require(`@/assets/img/partners/01_eu.png`)" alt=""></div>
			<div class="logo" :class="'logo' + randNum"><img :src="require(`@/assets/img/partners/02_rvn.png`)" alt=""></div>
			<div class="logo" :class="'logo' + randNum"><img :src="require(`@/assets/img/partners/03_rv.png`)" alt=""></div>

			<div class="logo" :class="'logo' + randNum"><img :src="require(`@/assets/img/partners/04_ok.png`)" alt=""></div>
			<div class="logo" :class="'logo' + randNum"><img :src="require(`@/assets/img/partners/05_s.png`)" alt=""></div>
			<div class="logo" :class="'logo' + randNum"><img :src="require(`@/assets/img/partners/06_u.png`)" alt=""></div>
			<!-- <div class="logo" :class="'logo' + randNum"><img :src="require(`@/assets/img/partners/07_ri.png`)" alt=""></div> -->

		</div>
	</div>
</template>

<script>
import { ref, onMounted } from 'vue'
import gsap from 'gsap'

export default {
	name: 'Logos',
	props: {
	},
	setup(props){
		const randNum = '_' + Math.floor(Math.random() * 1000000)

		// INTERCEPT
		const target = ref();
		let onlyOnce = true;
		const observer = new IntersectionObserver(
			([entry]) => {
				if(entry.isIntersecting == true){
					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{ threshold: 0.5 } // 0.7
			);

		// INTERCEPT ANIMATION
		const inView = () => {
			var logos = document.getElementsByClassName('logo' + randNum);

			for(let i = 0; i < logos.length; i++){
				gsap.to(logos[i], {
					duration: 0.7,
					opacity: 1,
					x: '0',
					y: 0,
					scale: 1,
					ease: 'power2.out',
					delay: 0.3 + (i * 0.05)
				})
			}
		}
		// END INTERCEPT

		onMounted(() => {
			// onMounted INTERCEPT
			observer.observe(target.value);

			gsap.set('.logo' + randNum, { x: '-=30', opacity: 0})
		})
		return{
			randNum,
			target
		}
	}
}
</script>

<style>

</style>
