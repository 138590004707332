<template>
	<section class="section_ripple_effect_intro" :id="'ripple_effect_intro' + randNum">
			<!-- parallax -->
			<div class="parallax_container">
				<div
					class="parallax_image animated_image fullwidth" 
					:id="'parallax_image' + randNum"
					>
					<div class="inner_bg_repeat" :style="{ backgroundImage: 'url(' + require(`@/assets/img/bgs/grids/grid_01_journey.png`) + ')' }"></div>
				</div>
			</div>
			
		<div class="ripple_effect_intro_container" ref="target">

			<h2 class="animated_header">
				<span :class="'header_bold' + randNum" class="header_bold" v-html="ita_headline_first"></span>
				<span :class="'header_light' + randNum" class="header_light" v-html="ita_headline_second"></span>
			</h2>
			<div class="flex">
				<div class="flex-half">
					<div class="text" :class="'text' + randNum" v-html="text_before_accordion"></div>
				</div>
				<div class="flex-half">
					<div class="text" :class="'text2' + randNum" v-html="text_before_accordion2"></div>
				</div>
			</div>


			<img :src="require(`@/assets/img/article_ripple_opt.png`)" :id="'ripple_image' + randNum" class="ripple_image" alt="Ripple effect image">

		</div>
	</section>
</template>

<script>
import { computed, ref, onMounted, onUnmounted } from 'vue'
import gsap from 'gsap'
import {SplitText} from '@/assets/js/SplitText.min.js';

export default {
	name:'RippleEffectIntro',
	props: {
		ita_headline_first: String,
		ita_headline_second: String,
		text_before_accordion: String,
		text_before_accordion2: String

	},
	components: {
	},
	setup(props){
		const randNum = '_' + Math.floor(Math.random() * 1000000);
		const target = ref();
		const ita_headline_first = props.ita_headline_first
		const ita_headline_second = props.ita_headline_second
		const text_before_accordion = props.text_before_accordion
		const text_before_accordion2 = props.text_before_accordion2

		// INTERCEPT
		let onlyOnce = true;
		const observer = new IntersectionObserver(
			([entry]) => {
				if(entry.isIntersecting == true){
					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{ threshold: 0.5 } // 0.7
			);

		// INTERCEPT ANIMATION
		const inView = () => {
			
			// header
			const header_bold = new SplitText('.header_bold' + randNum, {type:"words"})
			const header_light = new SplitText('.header_light' + randNum, {type:"words"})
			gsap.set(header_bold.words, {opacity: 0, x: '0', y: '30', scale: 1})
			gsap.set(header_light.words, {opacity: 0, x: '-50', y: '0', scale: 1})
			gsap.set(['.header_bold' + randNum,'.header_light' + randNum], {opacity: 1})
			
			// header bold loop
			for(let i = 0; i < header_bold.words.length; i++){
				gsap.to(header_bold.words[i], {
					duration: 0.7,
					opacity: 1,
					x: '0',
					y: 0,
					scale: 1,
					ease: 'power2.out',
					delay: 0.3 + (i * 0.05	)
				})
			}

			// header_light loop
			for(let i = 0; i < header_light.words.length; i++){
				gsap.to(header_light.words[i], {
					duration: 0.7,
					opacity: 1,
					x: '0',
					y: 0,
					scale: 1,
					ease: 'power2.out',
					delay: 0.7 + (i * 0.05	)
				})
			}

			// text
			gsap.set(['.text' + randNum, '.text2' + randNum], {opacity: 0, y: '30'})

			gsap.to('.text' + randNum, {
				duration: 1.0,
				y: 0,
				left: 0,
				opacity: 1,
				ease: 'power2.out',
				delay: 0.9
			})

			gsap.to('.text2' + randNum, {
				duration: 1.0,
				y: 0,
				left: 0,
				opacity: 1,
				ease: 'power2.out',
				delay: 1.1
			})

			// image
			gsap.set('#ripple_image' + randNum, {opacity: 0, scale: 0.7})

			gsap.to('#ripple_image' + randNum, {
				duration: 0.8,
				y: 0,
				left: 0,
				scale: 1,
				opacity: 1,
				ease: 'back.out(1.7)',
				delay: 1.5
			})

		}
		// END INTERCEPT

		onMounted(()=>{
			const body = document.getElementsByTagName("BODY")[0];		
			const comp = document.getElementById('ripple_effect_intro' + randNum);
			const offset = comp.getBoundingClientRect();
			const top = offset.top;
			const height = comp.offsetHeight;
			
			// SCROLL PARALLAX
			const handleScroll = (evt) => {
				const window_height = window.innerHeight
				const image = document.getElementById('parallax_image' + randNum);
				const comp = document.getElementById('ripple_effect_intro' + randNum);
				const comp_box = comp.getBoundingClientRect();
				const comp_height = comp.offsetHeight // 812
				const comp_top = comp_box.top;

				if(comp_top <= window_height){
					const value = -1 * (((250 / (window_height + comp_height)) * comp_top) + 125)
					image.style.top = value + 'px'
				}
			}

			document.addEventListener('scroll', handleScroll)
			handleScroll()
			// END SCROLL PARALLAX

			// onMounted INTERCEPT
			observer.observe(target.value);

			// header
			gsap.set(['.header_bold' + randNum, '.header_light' + randNum, '.text' + randNum, '#ripple_image' + randNum], {opacity: 0})

		})

		return {
			ita_headline_first,
			ita_headline_second,
			text_before_accordion,
			text_before_accordion2,
			target,
			randNum
		}
	}
}
</script>
